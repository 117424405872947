import FontLoaderAndSetter from '@/src/Styles/FontLoaderAndSetter';
import App, { AppContext, AppProps } from 'next/app';
import '@/src/Styles/global.scss';
function MyApp({ Component, pageProps }: AppProps) {
  return (
    <FontLoaderAndSetter>
      <Component {...pageProps} />
    </FontLoaderAndSetter>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const ctx = await App.getInitialProps(appContext);
  return {
    ...ctx,
    props: { isProduction: process.env.NODE_ENV === 'production' },
  };
};

export default MyApp;
