import localFont from 'next/font/local';
import React from 'react';

const Circular = localFont({
  src: [
    // { path: '../fonts/Small.ttf', weight: '200', style: 'normal' },
    // { path: '../fonts/Regular.ttf', weight: '400', style: 'normal' },
    { path: '../fonts/Medium.ttf', weight: '400', style: 'normal' },
    { path: '../fonts/Bold.ttf', weight: '600', style: 'normal' },
    { path: '../fonts/Black.ttf', weight: '700', style: 'normal' },
  ],
  preload: true,
  display: 'swap',
  style: 'normal',
  variable: '--circular-font',
});

const FontLoaderAndSetter = ({ children }: { children: React.ReactNode }) => {
  return <main className={Circular.className}> {children} </main>;
};

export default FontLoaderAndSetter;
